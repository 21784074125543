$( document ).ready(function() {

  // Get started!

});

// Scroll
(function(){ 

    var scroll = (function() {

        var root = document.getElementsByTagName('html')[0];
        var cssClass = 'scrolled';
        var tollerance = 0; // Tolleranza in pixel

        var setupEventListener = function() {
            window.addEventListener('scroll', onScrollPage);
        };

        var onScrollPage = function () {
            
            if(scrollY > tollerance) {
                // Scrollato
                root.classList.add(cssClass);
            } else {
                // Stato iniziale di tolleranza
                root.classList.remove(cssClass);
            }

        }

        return {
            init: function() {
                setupEventListener();
            }
        }

    })();

    scroll.init();

})();

// Mobile menu
(function(){ 

  var burgherozzo = (function() {

      var root = document.getElementsByTagName('html')[0];
      var burgerElm = document.getElementById('burgherozzo');
      var menuElm = document.querySelector('.overlay-menu');

      var setupEventListener = function() {
          burgerElm.addEventListener('click', openMenu);
          window.addEventListener('resize', closeMenu);           
      };

      var openMenu = function() {
          menuElm.classList.toggle('open');
          burgerElm.classList.toggle('active');
          root.classList.toggle('menu-open');
      };
      
      var closeMenu = function() {
          menuElm.classList.remove('open');
          burgerElm.classList.remove('active');
          root.classList.remove('menu-open');
      };

      return {
          init: function() {
              setupEventListener();
          }
      }

  })();
  burgherozzo.init();
})(); 